import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRedirectOnChange } from '../../hooks/redirectOnChange';
import { getOryLoginStatusSelector } from '../../modules/auth/selectors';
import { OryApiRequestCancelTokenId } from '../../ory/config/const';
import { useOryLoginApi } from '../../ory/hooks/oryApi';
import { useAppContext } from '../App/AppContextProvider';
import { logoutAndNavigateToLogin } from './logout';

export function LogoutPage(): JSX.Element {
  const { loggedIn } = useAppContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const oryLoginStatus = useSelector(getOryLoginStatusSelector);
  const { logoutFromOry } = useOryLoginApi();

  useRedirectOnChange();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const logout = async () => {
      await logoutAndNavigateToLogin({ cancelTokenSource, dispatch, logoutFromOry, navigate, oryLoginStatus });
    };

    logout();

    return () => {
      cancelTokenSource.cancel(OryApiRequestCancelTokenId);
    };
  }, [loggedIn]);

  return <>{false}</>;
}
