import { CancelTokenSource } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { PATHS } from '../../config/consts';
import { getUrlQueryParamValue, UrlQueryParamName } from '../../helpers/queryParams';
import { IUseLoginApi } from '../../ory/hooks/oryApi';
import { OryCancelled, OryLoginStatus } from '../../ory/types';

export async function logoutAndNavigateToLogin({
  cancelTokenSource,
  dispatch,
  logoutFromOry,
  navigate,
  oryLoginStatus,
}: {
  dispatch: Dispatch;
  navigate: NavigateFunction;
  oryLoginStatus: OryLoginStatus | undefined;
  logoutFromOry: IUseLoginApi['logoutFromOry'];
  cancelTokenSource: CancelTokenSource;
}) {
  const isOrySessionActive = getUrlQueryParamValue({ paramName: UrlQueryParamName.IsOrySessionActive });

  const tryToLogoutFromOry = async () => {
    const result = await logoutFromOry({ cancelToken: cancelTokenSource.token });
    if (result !== OryCancelled) {
      dispatch({ type: 'store/reset' });
      navigate(PATHS.LOGIN);
    }
  };
  if (oryLoginStatus === OryLoginStatus.SessionConfirmed || isOrySessionActive) {
    await tryToLogoutFromOry();
  } else {
    dispatch({ type: 'store/reset' });
    navigate(PATHS.LOGIN);
  }
}
